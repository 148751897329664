import React from "react"

import "../components/layout.css"

const style = {
  background: "linear-gradient(90deg, rgba(201,255,191,1) 0%, rgba(255,175,189,1) 100%)",
  height: "100vh"
}

const Gradient = () => (
  <div style={style}>
  </div>
)

export default Gradient
